import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { IDeleteConfirmation } from 'src/app/state/emitters/emiters-interfaces';
import { IEventSchedule } from 'src/app/state/event-schedule.repository';

@Component({
  selector: 'app-action-button-modal',
  templateUrl: './action-button-modal.component.html',
  styleUrls: ['./action-button-modal.component.scss'],
})
export class ActionButtonModalComponent {
  constructor(public router: Router) { }
  @Input() top: number = 0;
  @Input() left: number = 0;
  @Input() tabSelected: string = 'overview';
  @Input() actionDate: 1 | 2 = 1;

  localEvent: IEventSchedule | null = null;
  showOpenTicket = false;
  @Input() set event(value: IEventSchedule | null) {
    this.localEvent = value;
    if (this.localEvent?.date) {
      var date = dayjs();
      this.showOpenTicket = dayjs(this.localEvent.date) >= date;
    }
  }

  @Output() openActivityLogs = new EventEmitter<void>();
  @Output() deleteConfirmation = new EventEmitter<IDeleteConfirmation>();
  openTicket() {
    if (this.localEvent?.id)
      this.router.navigateByUrl(`/prefill/ticket/${this.localEvent.id}`)
  }
}
