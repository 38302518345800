<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="max-900 row">
    <div
      class="col-12 col-lg-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2 d-flex align-items-center"
    >
      <h2 class="page-title oneLineTextHeader" i18n="Label eventSchedule">
        Backend Teams
      </h2>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4">
    <app-datalist-select
      class=""
      [activeValue]="this.monthSelectedName"
      [options]="monthsOpt"
      (activeValueChange)="
        this.TeamSelected = null; repo.setBackendActivemonth($event)
      "
      [multiple]="false"
      label=""
      i18n-label=""
    ></app-datalist-select>
  </div>

  <div class="col-2">
    <app-datalist-select
      id="yearsSelect"
      [options]="yearsOptions"
      [multiple]="false"
      [(activeValue)]="selectedYear"
      (activeValueChange)="setYear($event)"
    >
    </app-datalist-select>
  </div>
  <div class="col-2">
    <app-datalist-select
      class=""
      [options]="TeamsOptions"
      [activeValue]="this.TeamSelected"
      (activeValueChange)="
        this.TeamSelected = $event; repo.filterBackendByTeam($event)
      "
      [multiple]="false"
      label=""
      i18n-label=""
    ></app-datalist-select>
  </div>
</div>

<div
  class="my-2 box"
  *ngIf="
    repo.backendActiveMonth?.showEventSchedules &&
    repo.backendActiveMonth?.showEventSchedules?.length
  "
>
  <div class="box-name-header w-100">
    <div class="d-flex p-2 d-flex justify-content-between">
      <div class="w-50p">
        <span class="txt mt-1">Status</span>
      </div>
      <div class="w-50p">
        <span class="txt mt-1">CC</span>
      </div>
      <div class="w-300p">
        <span class="txt mt-1">Client</span>
      </div>
      <div class="w-100p">
        <span class="txt mt-1">Date</span>
      </div>
      <div class="w-400p">
        <span class="txt mt-1">Location</span>
      </div>
      <div class="w-100p">
        <span class="txt mt-1">Urgent</span>
      </div>
      <div class="w-250p text-center">
        <span class="txt mt-1">Funnel & Automation</span>
      </div>
      <div class="w-250p text-center">
        <span class="txt mt-1">Qa by</span>
      </div>
      <div class="w-250p text-center">
        <span class="txt mt-1">Ads</span>
      </div>
      <div class="w-250p text-center">
        <span class="txt mt-1">Ads Qa by</span>
      </div>
    </div>
  </div>
  <div>
    <div *ngVar="repo.backendActiveMonth?.showEventSchedules as events">
      <div *ngFor="let event of events; let curentEventIndex = index" class="">
        <div class="" style="width: auto">
          <div
            class="d-flex px-2 h60 justify-content-between"
            [class.b-top-gray]="
              events.length &&
              curentEventIndex !== 0 &&
              curentEventIndex !== events.length
            "
          >
            <div class="d-flex align-items-center w-50p pointer">
              <app-event-status
                (click)="openEventStatus($event, 1, curentEventIndex, event.id)"
                [status]="event.status"
                [isMonthStatus]="false"
                [statusNum]="1"
              >
              </app-event-status>
            </div>
            <div class="d-flex align-items-center w-50p">
              <div
                class="text-break oneLineText pointer"
                (click)="showModal(event.id)"
              >
                {{ event.client.clientCode }}
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-300p">
              <div class="text-break oneLineText">
                <a
                  *ngIf="event.clientUrl"
                  class="pointer"
                  (click)="goToLink(event.clientUrl)"
                >
                  {{ event.client.title }}
                </a>
                <span
                  *ngIf="!event.clientUrl"
                  [class.client_blue]="event.wfrDinnerOrLunch"
                  [class.color-orange]="event.twoPartSetup"
                >
                  {{ event.client.title }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center w-100p">
              <div class="text-break oneLineText">
                {{ event.formatedDateAdmin }}
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-400p">
              <div class="text-break oneLineText">
                <a
                  *ngIf="event.landingPageUrl"
                  class="pointer"
                  (click)="goToLink(event.landingPageUrl)"
                >
                  {{ event.eventScheduleLocation?.title }}
                </a>
                <span *ngIf="!event.landingPageUrl">
                  {{ event.eventScheduleLocation?.title }}
                </span>

                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-100p">
              <div class="text-break oneLineText">
                {{ event.asap ? "Yes" : "No" }}
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnel?.name }}
                <br />
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelQAby?.name }}

                <br />
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelAds?.name }}
                <br />
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelAdsQAby?.name }}
                <br />
              </div>
            </div>
          </div>
          <div
            *ngIf="event.doubleEvent"
            class="d-flex px-2 h60 justify-content-between"
          >
            <div class="d-flex align-items-center w-50p">
              <div class="text-break oneLineText pointer">
                <app-event-status
                  (click)="
                    openEventStatus($event, 2, curentEventIndex, event.id)
                  "
                  [status]="event.statusD2"
                  [isMonthStatus]="false"
                  [statusNum]="2"
                >
                </app-event-status>
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-50p">
              <div
                class="text-break oneLineText pointer"
                (click)="showModal(event.id)"
              >
                {{ event.client.clientCode }}
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-300p">
              <div class="text-break oneLineText">
                <a
                  *ngIf="event.clientUrl"
                  class="pointer"
                  (click)="goToLink(event.clientUrl)"
                >
                  {{ event.client.title }}
                </a>
                <span
                  *ngIf="!event.clientUrl"
                  [class.client_blue]="event.wfrDinnerOrLunch"
                  [class.color-orange]="event.twoPartSetup"
                >
                  {{ event.client.title }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center w-100p">
              <div class="text-break oneLineText">
                {{ event.formatedDate2Admin }}
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-400p">
              <div class="text-break oneLineText">
                <a
                  *ngIf="event.landingPageUrl2"
                  class="pointer"
                  (click)="goToLink(event.landingPageUrl2)"
                >
                  {{ event.eventScheduleLocation?.title }}
                </a>
                <span *ngIf="!event.landingPageUrl2">
                  {{ event.eventScheduleLocation?.title }}
                </span>
                <br />
              </div>
            </div>
            <div class="d-flex align-items-center w-100p">
              <div class="text-break oneLineText">
                {{ event.asap ? "Yes" : "No" }}

                <br />
              </div>
            </div>

            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelSecond?.name }}
                <br />
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelSecondQAby?.name }}

                <br />
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelSecondAds?.name }}
                <br />
              </div>
            </div>
            <div
              class="d-flex align-items-center w-250p justify-content-center"
            >
              <div class="text-break oneLineText">
                {{ event.funnelSecondAdsQAby?.name }}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="myModal" class="modal">
  <div class="responsive">
    <div class="modal-content hide-scrollbar">
      <div class="d-flex modalHeader pb-2">
        <div
          class="d-flex justify-content-center align-items-center BlowModalIcon"
        >
          <svg
            width="22"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M19 9H1M14 1V5M6 1V5M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              s
            />
          </svg>
        </div>
        <div
          class="w-100 d-flex justify-content-between align-items-center ps-2"
        >
          <h5 class="m-0">Event Schedule</h5>
          <div class="w-50 d-flex align-items-center pe-5">
            <div class="w-100 d-flex justify-content-end add pe-5">
              <a
                [routerLink]="[
                  '/admin/eventSchedule/eventSchedule/',
                  eventScheduleOnPopUp?.id
                ]"
                class="d-flex justify-content-center btn btn-lg dark-wfrBlue text-white"
                style="justify-content: end; width: 140px !important"
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="text-btn-add" i18n="@@addCompany">Edit Event</span>
              </a>
            </div>
          </div>
        </div>
        <span class="close" (click)="hidemodal()">
          <svg
            width="12"
            height="12"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/1700/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#667085"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <div class="marginsModal">
        <form enctype="multipart/form-data">
          <app-event-pop-up
            [eventScheduleOnPopUp]="eventScheduleOnPopUp"
          ></app-event-pop-up>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="editedEventId">
  <app-change-status-modal
    [top]="statusTop"
    [left]="statusLeft"
    [eventId]="editedEventId!"
    (mouseLeave)="mouseLeave()"
    [statusIndex]="dateIndex"
    (submit)="changeStatus(editedEventId!, $event, dateIndex!); mouseLeave()"
    [hidden]="
      clickedEventIndex == null || dateIndex == null || editedEventId == null
    "
  ></app-change-status-modal>
</div>
