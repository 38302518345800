import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ITopic } from '../../../../state/event-schedule.repository';
import { TopicsRepository } from '../../../../state/topics.repository';
import { TopicsService } from '../../../../state/topics.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-topics-create-page',
  templateUrl: './topics-create-page.component.html',
  styleUrls: ['./topics-create-page.component.scss'],
})
export class TopicsCreatePageComponent {
  constructor(
    public service: TopicsService,
    public repo: TopicsRepository,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  topic: ITopic | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id !== 'new') {
          this.service.loadOneTopic(`${value.get('id')}`).subscribe((x) => {
            this.topic = x;
          });
        }
      },
    });
  }

  createTopic(event: Partial<ITopic>) {
    this.submitErrors = null;
    let updateResult$: Observable<ITopic> | undefined;
    if (this.id === 'new') {
      updateResult$ = this.service.postTopic(event);
    } else if (this.id) {
      updateResult$ = this.service.updateTopic(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.router.navigate(['/admin/topics']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
  locationBack() {
    this.location.back();
  }
}
