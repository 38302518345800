import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { AuthRepository } from '../../../../state/auth.repository';
import {
  EventScheduleRepository,
  IActivityLog,
  IEventSchedule,
} from '../../../../state/event-schedule.repository';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-event-schedule-create-page',
  templateUrl: './event-schedule-create-page.component.html',
  styleUrls: ['./event-schedule-create-page.component.scss'],
})
export class EventScheduleCreatePageComponent {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public service: EventScheduleService,
    public repo: EventScheduleRepository,
    private route: ActivatedRoute,
    private router: Router,
    public authrepo: AuthRepository,
    public location: Location
  ) {}
  Form: UntypedFormBuilder | any;
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  eventSchedule: IEventSchedule | null = null;
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      title: ['', Validators.required],
    });
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service
            .loadOneEventSchedule(`${value.get('id')}`)
            .subscribe((x) => {
              this.eventSchedule = x;
              // this.eventSchedule.comments = this.eventSchedule.comments.sort(
              //   (a, b) =>
              //     new Date(b.createdAt!).getTime() -
              //     new Date(a.createdAt!).getTime()
              // );
            });
        }
      },
    });
  }

  createEvent(event: Partial<IEventSchedule>) {
    this.submitErrors = null;
    let updateResult$: Observable<IEventSchedule> | undefined;
    if (this.id === 'new') {
      updateResult$ = this.service.postEventSchedule(event);
    } else if (this.id) {
      updateResult$ = this.service.updateEventSchedule(event);
    }
    if (updateResult$) {
      updateResult$.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }

  // onSubmit() {
  //   const text = this.Form.value?.title.toString();
  //   const eventScheduleId = this.eventSchedule?.id
  //     ? this.eventSchedule?.id
  //     : '';
  //   this.service.AddComment({ text, eventScheduleId }).subscribe((x) => {
  //     this.eventSchedule?.comments.push(x);
  //     this.eventSchedule!.comments = this.eventSchedule!.comments.sort(
  //       (a, b) =>
  //         new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
  //     );
  //     this.Form.get('title').setValue('');
  //     this.Form.markAsUntouched();
  //     this.Form.updateValueAndValidity();
  //   });
  // }

  locationBack() {
    this.location.back();
  }
}
