import { Injectable } from '@angular/core';
import { createState, createStore, Store } from '@ngneat/elf';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, tap } from 'rxjs';
import { sortBy, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { IDocumentType } from './documenttype.repository';
import { IFolder } from './folders.repository';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';

export const CompanyStatusesOptions: DatalistOption[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Disabled', value: 'Disabled' },
];

export interface ILastUsed {
  id: string;
  date?: Date;
}
export interface ICompanyLocation {
  id: string;
  title: string;
  locationTitle: string;
  order: number;
  address: string;
  latitude: number;
  longitude: number;
  checked: boolean;
  circleSizeLabel: string;
  circleSize: number;
  tenantId: string;
  defaultRadius: number;
  radius: number;
  companyId: string;
  lastEventDate: Date | null;
  lastEventDescription: string;
  formatedLastEventDate: string;
  fillColor?: string;
  shown: boolean;
  locationUrl: boolean;
  parking: string;
}
export interface IScreenOptions {
  lng: number;
  lat: number;
  zoom: number;
}
export interface IClientOption {
  id: string;
  clientCode: string;
  title: string;
}
export interface ICompanyMarker {
  id: string;
  status?: string;

  title: string;
  description: string;
  updatedAt: Date;
  companyLocations: ICompanyLocation[];
  address: string;
  clientSince: Date;
  acquiredBy: string;
  formatedClientSince: string;
  tenantId: string;
  allCompaniesSelected: boolean;
  aeAdvisor: boolean;
  locationsHiden: boolean;
  latitude: number;
  longitude: number;
  clientCode?: string;
  pricing?: string;
  cellPhones: ICellPhone[];
  invoice?: IInvoice;
  contacts: IContact[];
  notes?: string;
  fillColor?: string;
  clientUrl: boolean;
  feePerEvent: number;
  folder: string;
  checkboxDisabled?: boolean;
  clientSpecificInstructions?: string;
  referralSourceId?: string;
}

export interface IMapInfo {
  id: string;
  zoom: number;
  startingAddress: string;
  startingLat: number;
  startingLng: number;
  useKmAsValues: boolean;
}

export interface ICellPhone {
  id: string;
  phoneNumber: string;
}

export interface IContact {
  id: string;
  name: string;
  email: string;
  phoneNumber?: string;
  companyId: string;
}

export interface IInvoice {
  id: string;
  billTo?: string;
  email?: string;
  description?: string;
}

export const CirclesOptions: DatalistOption[] = [
  { label: '3 miles', value: 4828.03 },
  { label: '4 miles', value: 6437.37 },
  { label: '5 miles', value: 8046.72 },
  { label: '6 miles', value: 9656.06 },
  { label: '7 miles', value: 11265.4 },
  { label: '8 miles', value: 12874.75 },
  { label: '9 miles', value: 14484.09 },
  { label: '10 miles', value: 16093.44 },
  { label: '12 miles', value: 19312.12 },
  { label: '15 miles', value: 24140.16 },
];
export const ColorsOptions: DatalistOption[] = [
  { label: 'Red', value: 'red' },
  { label: 'Blue', value: 'blue' },
  { label: 'Green', value: 'green' },
  { label: 'Gray', value: 'gray' },
  { label: 'Orange', value: 'orange' },
  { label: 'Pink', value: 'pink' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Magenta', value: 'magenta' },
  { label: 'Purple', value: 'Purple' },
  { label: 'Lime', value: 'lime' },
];
export const CirclesOptionsKm: DatalistOption[] = [
  { label: '3 KM', value: 3000.0 },
  { label: '4 KM', value: 4000.0 },
  { label: '5 KM', value: 5000.0 },
  { label: '6 KM', value: 6000.0 },
  { label: '7 KM', value: 7000.0 },
  { label: '8 KM', value: 8000.0 },
  { label: '9 KM', value: 9000.0 },
  { label: '10 KM', value: 10000.0 },
  { label: '12 KM', value: 12000.0 },
  { label: '15 KM', value: 15000.0 },
];

export const CompaniesSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

const { state, config } = createState(
  withEntities<ICompanyMarker>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'companies', state, config });

@Injectable({ providedIn: 'root' })
export class CompaniesRepository extends BaseRepository<ICompanyMarker> {
  constructor() {
    super('DocumentsOptions', CompaniesSortOptions);
  }
}
