import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionOverStateComponent } from './components/session-over-state/session-over-state.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthlayoutComponent } from './layouts/authlayout/authlayout.component';
import { ApphomelayoutComponent } from './layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from './layouts/appinnerlayout/appinnerlayout.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ForgetpasswordComponent } from './pages/forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { VerifyComponent } from './layouts/authlayout/verify/verify.component';
import { FooterinfoComponent } from './components/footerinfo/footerinfo.component';
import { HeaderbackComponent } from './components/headerback/headerback.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PagenotauthorizedComponent } from './pages/pagenotauthorized/pagenotauthorized.component';
import { TenantsCreatePageComponent } from './pages/tenants-create-page/tenants-create-page.component';
import { ProfileEditComponent } from './pages/profile-edit/profile-edit.component';
import { UsersCreatePageComponent } from './pages/users-create-page/users-create-page.component';
import { HeadermenuComponent } from './components/headermenu/headermenu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TenantsPageComponent } from './pages/tenants-page/tenants-page.component';
import { UsersListPageComponent } from './pages/users-list-page/users-list-page.component';
import { ImpersonationModalComponent } from './components/impersonation-modal/impersonation-modal.component';
import { TenantFormComponent } from './components/tenant-form/tenant-form.component';
import { UserBasicInfoFormComponent } from './components/user-basic-info-form/user-basic-info-form.component';
import { UserHeaderImageComponent } from './components/user-header-image/user-header-image.component';
import { TenantListComponent } from './components/tenant-list/tenant-list.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LogoComponent } from './components/logo/logo.component';
import { SharedModule } from '../shared/shared.module';
import { OfflineLabelComponent } from './components/offline-label/offline-label.component';
import { PwaInstallButtonComponent } from './components/pwa-install-button/pwa-install-button.component';
import { UnsyncedChangesComponent } from './components/unsynced-changes/unsynced-changes.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { TimelogsGridComponent } from './components/timelogs-grid/timelogs-grid.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Office365authComponent } from './components/office365auth/office365auth.component';
import { MenuComponent } from './components/menu/menu.component';
import { DocumentPostComponent } from './components/document-post/document-post.component';
import { MenuUserCardComponent } from './components/menuuser-card/menuuser-card.component';
import { FolderPostComponent } from './components/folder-post/folder-post.component';
import { FolderpageComponent } from './pages/folderpage/folderpage.component';
import { DocumenttypePostComponent } from './components/documenttype-post/documenttype-post.component';
import { DocumenttypePageComponent } from './pages/documenttype-page/documenttype-page.component';
import { DocumenttypeEditpageComponent } from './pages/documenttype-editpage/documenttype-editpage.component';
import { DocumentEditpageComponent } from './pages/document-editpage/document-editpage.component';
import { FolderEditpageComponent } from './pages/folder-editpage/folder-editpage.component';
import { FolderEditformComponent } from './components/folder-editform/folder-editform.component';
import { DocumentEditformComponent } from './components/document-editform/document-editform.component';
import { DocumenttypeEditformComponent } from './components/documenttype-editform/documenttype-editform.component';
import { AdminDocumentPageComponent } from './pages/admin-document-page/admin-document-page.component';
import { DocumenttypeFromSideMenuComponent } from './components/documenttype-from-SideMenu/documenttype-from-SideMenu.component';
import { MapsPageComponent } from './pages/maps-page/maps-page.component';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AdminCompaniesPageComponent } from './pages/admin-companies-page/admin-companies-page.component';
import { CompaniesEditformComponent } from './components/companies-editform/companies-editform.component';
import { ClientsCreatePageComponent } from './pages/clients-create-page/clients-create-page.component';
import { AdminLocationsPageComponent } from './pages/admin-locations-page/admin-locations-page.component';
import { AdminContactsPageComponent } from './pages/admin-contacts-page/admin-contacts-page.component';
import { ContactsCreatePageComponent } from './pages/contacts-create-page/contacts-create-page.component';
import { LocationsCreatePageComponent } from './pages/locations-create-page/locations-create-page.component';
import { LocationsEditformComponent } from './components/locations-editform/locations-editform.component';
import { ContactsEditformComponent } from './components/contacts-editform/contacts-editform.component';
import { AdminRoomsPageComponent } from './pages/admin-rooms-page/admin-rooms-page.component';
import { RoomsCreatePageComponent } from './pages/rooms-create-page/rooms-create-page.component';
import { RoomsEditformComponent } from './components/rooms-editform/rooms-editform.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { EventScheduleModule } from '../event-schedule/event-schedule.module';
import { CompaniesStatusPopUpComponent } from './components/companies-status-pop-up/companies-status-pop-up.component';
import { ClientUsersPageComponent } from './pages/client-users-page/client-users-page.component';
import { ClientUsersCreatePageComponent } from './pages/client-users-create-page/client-users-create-page.component';
import { ClientUsersEditformComponent } from './components/client-users-editform/client-users-editform.component';
import { SearchModalAbsoluteComponent } from './components/search-modal-absolute/search-modal-absolute.component';
import { EditLocationPopoverComponent } from './components/edit-location-popover/edit-location-popover.component';
import { GtfaQrcodeComponent } from './components/gtfa-qrcode/gtfa-qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { Show2faCredsPopUpComponent } from './components/show2fa-creds-pop-up/show2fa-creds-pop-up.component';

@NgModule({
  declarations: [
    SigninComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    SessionOverStateComponent,
    AuthlayoutComponent,
    ApphomelayoutComponent,
    AppinnerlayoutComponent,
    FooterinfoComponent,
    HeaderbackComponent,
    PagenotfoundComponent,
    PagenotauthorizedComponent,
    TenantsCreatePageComponent,
    ProfileEditComponent,
    UsersCreatePageComponent,
    HeadermenuComponent,
    SidebarComponent,
    ProfileComponent,
    TenantsPageComponent,
    UsersListPageComponent,
    ImpersonationModalComponent,
    TenantFormComponent,
    UserBasicInfoFormComponent,
    UserHeaderImageComponent,
    TenantListComponent,
    UserCardComponent,
    UsersListComponent,
    LogoComponent,
    OfflineLabelComponent,
    PwaInstallButtonComponent,
    UnsyncedChangesComponent,
    ChangePasswordFormComponent,
    HomepageComponent,
    TimelogsGridComponent,
    Office365authComponent,
    MenuComponent,
    DocumentPostComponent,
    MenuUserCardComponent,
    FolderPostComponent,
    FolderpageComponent,
    DocumenttypePostComponent,
    DocumenttypePageComponent,
    DocumenttypeEditpageComponent,
    DocumentEditpageComponent,
    FolderEditpageComponent,
    FolderEditformComponent,
    DocumentEditformComponent,
    DocumenttypeEditformComponent,
    DocumenttypeFromSideMenuComponent,
    AdminDocumentPageComponent,
    MapsPageComponent,
    AdminCompaniesPageComponent,
    CompaniesEditformComponent,
    ClientsCreatePageComponent,
    AdminLocationsPageComponent,
    AdminContactsPageComponent,
    ContactsCreatePageComponent,
    LocationsCreatePageComponent,
    LocationsEditformComponent,
    ContactsEditformComponent,
    AdminRoomsPageComponent,
    RoomsCreatePageComponent,
    RoomsEditformComponent,
    CompaniesStatusPopUpComponent,
    ClientUsersPageComponent,
    ClientUsersCreatePageComponent,
    ClientUsersEditformComponent,
    SearchModalAbsoluteComponent,
    EditLocationPopoverComponent,
    GtfaQrcodeComponent,
    Show2faCredsPopUpComponent,
  ],
  imports: [
    QRCodeModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    GooglePlaceModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot(),
    SharedModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyArK0PM_qJ07Bp3pBZ1ER2wlUNRInZ_eQc',
    }),
    EventScheduleModule,
  ],
  exports: [
    SigninComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    SessionOverStateComponent,
    AuthlayoutComponent,
    ApphomelayoutComponent,
    AppinnerlayoutComponent,
    FooterinfoComponent,
    HeaderbackComponent,
    PagenotfoundComponent,
    PagenotauthorizedComponent,
    TenantsCreatePageComponent,
    ProfileEditComponent,
    UsersCreatePageComponent,
    HeadermenuComponent,
    SidebarComponent,
    ProfileComponent,
    TenantsPageComponent,
    UsersListPageComponent,
    OfflineLabelComponent,
    PwaInstallButtonComponent,
    TimelogsGridComponent,
    UnsyncedChangesComponent,
    DocumentPostComponent,
  ],
})
export class CoreModule {}
