import { Component, HostListener } from '@angular/core';
import { IFunnel, ITopic } from '../../../../state/event-schedule.repository';
import {
  TopicsRepository,
  TopicsSortOptions,
} from '../../../../state/topics.repository';
import { TopicsService } from '../../../../state/topics.service';

@Component({
  selector: 'app-admin-topics-page',
  templateUrl: './admin-topics-page.component.html',
  styleUrls: ['./admin-topics-page.component.scss'],
})
export class AdminTopicsPageComponent {
  innerWidth = 0;
  searchFilter: string = '';
  sortOptions = TopicsSortOptions;
  topic?: ITopic;
  deleteConfirmation: ITopic | null = null;
  constructor(public repo: TopicsRepository, public service: TopicsService) {}
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
    //this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter, 25).subscribe();
  }
  handleDeleteClick(post: ITopic) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter, 25).subscribe();
  }
}
