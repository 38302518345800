import { state } from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import * as dayjs from 'dayjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  EventScheduleSortOptions,
  IActivityLog,
  IEventSchedule,
  IMarkingDot,
  IMonthState,
  IMonthWithEvents,
  IPageProperties,
} from '../../../../state/event-schedule.repository';
import { EventScheduleService } from '../../../../state/event-schedule.service';
import { AdminAdsViewEventScheduleComponent } from '../admin-ads-view-event-schedule/admin-ads-view-event-schedule.component';
import { AdminEventSchedulePageComponent } from '../admin-event-schedule-page/admin-event-schedule-page.component';
import { AdminPayStatsEventScheduleComponent } from '../admin-pay-stats-event-schedule/admin-pay-stats-event-schedule.component';
import { AdminToDoEventScheduleComponent } from '../admin-to-do-event-schedule/admin-to-do-event-schedule.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { SearchComponent } from '../../../shared/components/search/search.component';
import { EventAdminTabsComponent } from '../../components/event-admin-tabs/event-admin-tabs.component';
import { FinancialsEsComponent } from '../financials-es/financials-es.component';
import saveAs from 'file-saver';

import { Router } from '@angular/router';
import {
  IActionPopEmitter,
  IChangePayStatsEmitter,
  IEnterChangeStatus,
} from 'src/app/state/emitters/emiters-interfaces';
@Component({
  selector: 'app-event-schedules-page',
  templateUrl: './event-schedules-page.component.html',
  styleUrls: ['./event-schedules-page.component.scss'],
})
export class EventSchedulesPageComponent {
  months: IMonthWithEvents[] = [];
  searchFilter: string = '';
  sortOptions = EventScheduleSortOptions;
  IEventSchedule?: IEventSchedule;
  deleteConfirmation: IEventSchedule | null = null;
  deleteConfirmationDate: number | null = null;
  secondDeleteConfirmation: IEventSchedule | null = null;
  tabSelected: string = 'Overview';
  markingDotsOrange: IMarkingDot | undefined = undefined;
  markingDotsPink: IMarkingDot | undefined = undefined;
  deleteError: string[] | null = null;
  rawYears: number[] | null = null;
  eventScheduleOnPopUp: IEventSchedule | null = null;
  searchForCC: boolean = false;
  searchForLocation: boolean = false;
  searchForTopic: boolean = false;
  monthIndex: number | null = null;
  eventIndex: number | null = null;
  reminderRight: number | null = null;
  monthsState: IMonthState[] = [];
  monthsLoading: boolean = false;
  orangeControl: FormControl = new FormControl();
  pinkControl: FormControl = new FormControl();
  @ViewChild(SearchComponent) searchComponent: SearchComponent | null = null;
  @ViewChild(EventAdminTabsComponent)
  tabsComponent: EventAdminTabsComponent | null = null;
  searchType?: string;
  reminderTop: number | null = null;
  reminderLeft: number | null = null;
  actionTop: number | null = null;
  actionLeft: number | null = null;
  actionDate: 1 | 2 = 1;
  actionEvent: IEventSchedule | null = null;

  payStatsTop: number | null = null;
  payStatsLeft: number | null = null;

  activityLogsEventId: string = '';
  activityLogsLoading: boolean = false;
  actLogs: IActivityLog[] = [];

  manageActivityLogs(eventId: string) {
    this.activityLogsLoading = true;
    this.service.loadActivityLogs(eventId).subscribe((x) => {
      this.actLogs = x;
      this.activityLogsLoading = false;
    });
  }

  manageCalcFinances(
    eventInd: number,
    monInd: number,
    $event: any,
    date: number
  ) {
    let mon = this.months[monInd];
    if (
      mon &&
      mon.showEventSchedules &&
      mon.showEventSchedules?.length >= eventInd
    ) {
      let eve = this.months[monInd].showEventSchedules![eventInd];
      if (eve) {
        this.loadEvInd = eventInd;
        this.loadMonInd = monInd;
        this.calcFinances($event, eve, date - 1, monInd, eventInd);
      }
    }
  }
  loadEvInd: number | null = null;
  loadMonInd: number | null = null;

  calcFinances(
    $event: any,
    event: IEventSchedule,
    date: number,
    monId: number,
    eventId: number
  ) {
    let invads = document.getElementById(
      `inv-ads/${date}/${monId}/${eventId}`
    ) as HTMLInputElement;
    if (invads && invads.value.length) {
      date
        ? (event.invoicedAdsD2 = +invads.value)
        : (event.invoicedAds = +invads.value);
    }
    let qts = document.getElementById(
      `qts/${date}/${monId}/${eventId}`
    ) as HTMLInputElement;
    if (qts && qts.value.length) {
      date ? (event.qtsD2 = +qts.value) : (event.qts = +qts.value);
    }

    let flatFee = document.getElementById(
      `flat-fee/${date}/${monId}/${eventId}`
    ) as HTMLInputElement;
    if (flatFee && flatFee.value.length) {
      date
        ? (event.flatFeeD2 = +flatFee.value)
        : (event.flatFee = +flatFee.value);
    }

    date
      ? (event.totalInvoicesD2 = this.calcTotalInvoices(
          event.invoicedAdsD2 ?? 0,
          event.qtsD2 ?? 0,
          event.flatFeeD2 ?? 0
        ))
      : (event.totalInvoices = this.calcTotalInvoices(
          event.invoicedAds ?? 0,
          event.qts ?? 0,
          event.flatFee ?? 0
        ));

    date
      ? (event.costLeadD2 = this.calcCostLead(
          event.totalInvoicesD2 ?? 0,
          event.attendeesD2
        ))
      : (event.costLead = this.calcCostLead(
          event.totalInvoices ?? 0,
          event.attendees
        ));

    date
      ? (event.grossProfitD2 = this.calcGrossProfit(
          event.totalInvoicesD2 ?? 0,
          (event.metaAds ?? 0) + (event.otherAds ?? 0)
        ))
      : (event.grossProfit = this.calcGrossProfit(
          event.totalInvoices ?? 0,
          (event.metaAds ?? 0) + (event.otherAds ?? 0)
        ));

    this.service.updateEventFinancials(event).subscribe((x) => {
      this.refreshOne(x);
    });
  }
  calcTotalInvoices(invAds: number, flatFee: number, qts: number): number {
    return +(invAds + flatFee * qts).toFixed(2);
  }
  calcCostLead(totInv: number, atendees: number): number {
    if (atendees != 0) {
      return +(totInv / atendees).toFixed(2);
    } else return 0;
  }
  calcGrossProfit(totInv: number, totAds: number) {
    return +(totInv - totAds).toFixed(2);
  }
  manageRegBinding(eventInd: number, monInd: number, date: number) {
    let mon = this.months[monInd];
    if (
      mon &&
      mon.showEventSchedules &&
      mon.showEventSchedules?.length >= eventInd
    ) {
      let eve = this.months[monInd].showEventSchedules![eventInd];
      if (eve) {
        switch (date) {
          case 1: {
            this.goToLink(eve.googleSheet);
            break;
          }
          case 2: {
            this.goToLink(eve.googleSheetD2);

            break;
          }
        }
      }
    }
  }

  changePayStats(id: string, value: DatalistOption, date: number) {
    this.refreshOne({ id: id, payStats: value.value });
  }

  manageLocationUrls(eventInd: number, monInd: number, date: number) {
    let mon = this.months[monInd];
    if (
      mon &&
      mon.showEventSchedules &&
      mon.showEventSchedules?.length >= eventInd
    ) {
      let eve = this.months[monInd].showEventSchedules![eventInd];
      if (eve) {
        switch (date) {
          case 1: {
            this.goToLink(eve.landingPageUrl);
            break;
          }
          case 2: {
            this.goToLink(eve.landingPageUrl2);

            break;
          }
        }
      }
    }
  }

  constructor(
    public repo: EventScheduleRepository,
    public datepipe: DatePipe,
    public service: EventScheduleService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    public routerLink: Router
  ) {
    this.orangeControl.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      if (this.markingDotsOrange) {
        this.markingDotsOrange.name = res;
        this.service.setMarkingDot(this.markingDotsOrange).subscribe();
      }
    });

    this.pinkControl.valueChanges.pipe(debounceTime(500)).subscribe((res) => {
      if (this.markingDotsPink) {
        this.markingDotsPink.name = res;
        this.service.setMarkingDot(this.markingDotsPink).subscribe();
      }
    });
  }

  exportMonthsToCSV(mon: IMonthWithEvents) {
    this.service
      .exportMonthsToCsv(mon, this.tabSelected)
      .subscribe((data: Blob) => {
        saveAs(data, `${mon.name}.csv`);
      });
  }
  eventSchedules: IEventSchedule[] = [];
  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  yearsOptions: DatalistOption[] | null = [
    {
      value: this.Date?.year(),
      label: this.Date?.year().toString(),
    },
  ];

  enterChangeStatus(emiter: IEnterChangeStatus) {
    this.parentToUpdate = emiter.parentEntityId;
    this.statusLeft = emiter.pageX - 20;
    this.statusTop = emiter.pageY;
    this.dateIndex = emiter.date;
    this.editedEventId = emiter.entityId;
    this.parentToUpdate = emiter.parentEntityId;
  }
  clickedMonthIndex: number | null = null;
  clickedEventIndex: number | null = null;
  dateIndex: number | null = null;
  editedEventId: string | null = null;

  clickedDateIndex: string | null = null;
  statusTop: number = 0;
  statusLeft: number = 0;
  mouseLeave() {
    this.monthIndex = null;
    this.eventIndex = null;
    this.clickedMonthIndex = null;
    this.clickedEventIndex = null;
    this.statusLeft = 0;
    this.statusTop = 0;
    this.dateIndex = null;
    this.editedEventId = null;
    this.esIdOnPopUp = '';
    this.payStatsTop = null;
    this.payStatsLeft = null;
    this.reminderTop = null;
    this.reminderLeft = null;
    this.parentToUpdate = '';
    this.actionEvent = null;
    this.actionDate = 1;
    this.actionTop = null;
    this.actionLeft = null;
  }
  selectedYear: number | null = null;
  @Input() set years(value: number[] | null) {
    if (!value) {
      this.yearsOptions = null;
    } else {
      this.yearsOptions = value.map((x) => ({
        value: x,
        label: `${x}`,
      }));
    }
  }

  exportCheck(id: string) {
    let mon = this.months.find((x) => x.id == id);
    if (mon) {
      mon!.exportCheck = !mon.exportCheck;
      let monthFromProp = this.pageProperties.monthStates.find((x) =>
        mon!.name.includes(x.name)
      );
      if (monthFromProp) {
        this.pageProperties.monthStates.find((x) =>
          mon!.name.includes(x.name)
        )!.exportCheck = mon.exportCheck;

        this.service.setPageProperties(this.pageProperties).subscribe();
      }
    }
  }

  expand(id: string) {
    let mon = this.months.find((x) => x.id == id);
    if (mon) {
      mon!.expanded = !mon.expanded;

      let monthFromProp = this.pageProperties.monthStates.find((x) =>
        mon!.name.includes(x.name)
      );
      if (monthFromProp) {
        this.pageProperties.monthStates.find((x) =>
          mon!.name.includes(x.name)
        )!.expanded = mon.expanded;
        this.service.setPageProperties(this.pageProperties).subscribe();
      }
    }
    // this.addlisteners();
  }
  modal: any;

  ngOnInit(): void {
    this.refreshData(true);
    this.getMarkingDots();

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      var modal = document.getElementById('myModal') as HTMLModElement;
      if (event.currentTarget == modal) {
        modal.style.display = 'none';
      }
    };
  }

  getMarkingDots() {
    this.service.loadMarkingDots().subscribe((x) => {
      this.markingDotsOrange = x.find((x) => x.type == 'Orange');
      this.orangeControl.setValue(this.markingDotsOrange?.name);
      this.markingDotsPink = x.find((x) => x.type == 'Pink');
      this.pinkControl.setValue(this.markingDotsPink?.name);
    });
  }
  setLastStatusEvents(month: IMonthWithEvents) {
    let res = [] as IEventSchedule[];
    var lastStatus = this.pageProperties.monthStates.find((prop) =>
      month.name.includes(prop.name)
    )?.statusFilter;
    var lastStatusNum = this.pageProperties.monthStates.find((prop) =>
      month.name.includes(prop.name)
    )?.statusFilterNum;

    if (
      lastStatus == 'Automatic' &&
      lastStatusNum != undefined &&
      lastStatusNum != null &&
      lastStatusNum != 0
    ) {
      res = month.eventSchedules?.filter(
        (event) =>
          (event.status == lastStatus && +event.statusNum == lastStatusNum) ||
          (event.doubleEvent &&
            event.statusD2 == lastStatus &&
            +event.statusNumD2 == lastStatusNum)
      );
    } else if (
      lastStatus === 'Few Spots' &&
      this.pageProperties.tabSelected == 'to-Do'
    ) {
      switch (lastStatusNum) {
        case 1: {
          res = month.eventSchedules.sort(
            (a, b) =>
              new Date(a.fewSpots).getTime() - new Date(b.fewSpots).getTime()
          );
          break;
        }

        case 2: {
          res = month.eventSchedules.sort(
            (a, b) =>
              new Date(b.fewSpots).getTime() - new Date(a.fewSpots).getTime()
          );
          break;
        }
        default: {
          res = month.eventSchedules.sort(
            (a, b) =>
              new Date(a.fewSpots).getTime() - new Date(b.fewSpots).getTime()
          );
          break;
        }
      }
    } else if (
      lastStatus === 'Few Spots' &&
      this.pageProperties.tabSelected !== 'to-Do'
    ) {
      res = month.eventSchedules;
    } else if (lastStatus) {
      res = month.eventSchedules?.filter(
        (event) =>
          event.status == lastStatus ||
          (event.doubleEvent && event.statusD2 == lastStatus)
      );
    } else {
      res = month.eventSchedules;
    }
    return res;
  }

  setSearchType(type?: string) {
    this.searchType = type;
    switch (type) {
      case 'client': {
        this.searchForLocation = false;
        this.searchForTopic = false;
        !this.searchForCC ? (this.searchType = undefined) : true;
        break;
      }
      case 'location': {
        this.searchForTopic = false;
        this.searchForCC = false;
        !this.searchForLocation ? (this.searchType = undefined) : true;

        break;
      }
      case 'topic': {
        this.searchForLocation = false;
        this.searchForCC = false;
        !this.searchForTopic ? (this.searchType = undefined) : true;

        break;
      }
      default: {
        this.searchForLocation = false;
        this.searchForCC = false;
        this.searchForTopic = false;
        break;
      }
    }
    this.pageProperties.cc = this.searchForCC;
    this.pageProperties.topic = this.searchForTopic;
    this.pageProperties.location = this.searchForLocation;
  }

  activesubscription: any;

  handleSearch(type?: string) {
    this.setSearchType(type);

    if (this.pageProperties && this.pageProperties.id) {
      this.service.setPageProperties(this.pageProperties).subscribe();
    }

    this.refreshData();
  }

  hidemodal() {
    var modal = document.getElementById('myModal') as HTMLModElement;
    if (modal) modal.style.display = 'none';
  }
  eventSnapshotIsLoading = false;
  showModal(eventId: string) {
    this.eventScheduleOnPopUp = null;
    if (eventId) {
      this.eventSnapshotIsLoading = true;
      this.service.loadOneEventSchedule(eventId).subscribe((x) => {
        this.eventSnapshotIsLoading = false;
        this.eventScheduleOnPopUp = x;
      });
    }
  }

  findFromLocal(name: string) {
    let monthFromLocal = this.monthsState.find((month2) =>
      name.includes(month2.name)
    );
    if (monthFromLocal) {
      if (!monthFromLocal.expanded) return false;
    }
    return true;
  }
  findEpandFromLocal(name: string) {
    let monthFromLocal = this.monthsState.find((month2) =>
      name.includes(month2.name)
    );
    if (monthFromLocal) {
      if (!monthFromLocal.exportCheck) return false;
    }
    return true;
  }

  setActiveYear(year: number | null) {
    if (this.yearsOptions && this.yearsOptions.length) {
      var yearFromOptions = this.yearsOptions.find(
        (x) => x.value == year
      )?.value;
      if (year && yearFromOptions) {
        this.selectedYear = yearFromOptions;
      } else {
        var currentYearFromOptions = this.yearsOptions.find(
          (x) => x.value == this.Date?.year()
        )?.value;
        if (currentYearFromOptions) {
          this.selectedYear = this.Date?.year();
        } else
          this.selectedYear =
            this.yearsOptions[Math.max(this.yearsOptions.length - 1, 0)].value;
      }
    }
  }
  pageProperties: IPageProperties = {} as IPageProperties;

  defineSearchtype(): string | undefined {
    if (this.pageProperties.cc) return 'client';
    if (this.pageProperties.location) return 'location';
    if (this.pageProperties.topic) return 'topic';
    return undefined;
  }
  refreshData(getDataFromPageProps = false) {
    this.eventSchedules = [];

    this.activesubscription = this.service
      .getESPage(
        getDataFromPageProps,
        this.selectedYear ?? this.Date.year(),
        this.searchFilter,
        this.searchType
      )
      .subscribe((x) => {
        if (getDataFromPageProps) {
          this.pageProperties = x.pageProperties;
          this.searchType = this.defineSearchtype();
          this.searchComponent?.setValue(x.pageProperties.filterString);
          this.selectedYear = x.pageProperties?.year ?? this.Date.year();
          this.monthsState = x.pageProperties.monthStates;
          this.searchForCC = x.pageProperties.cc ?? false;
          this.searchForLocation = x.pageProperties.location ?? false;
          this.searchForTopic = x.pageProperties.topic ?? false;
          this.years = x.years;
        }
        this.setActiveYear(x.pageProperties?.year);

        this.months = x.months.map((month) => {
          if (this.tabSelected != this.pageProperties.tabSelected)
            this.changeTab(this.pageProperties.tabSelected);
          this.tabsComponent!.tabSelected2 = this.pageProperties.tabSelected;
          return {
            name: month.name,
            showEventsCount: 40,
            id: month.id,
            aeCount: month.aeCount,
            eventsCount: month.eventsCount,
            rescheduledCount: month.rescheduledCount,
            cancelledCount: month.cancelledCount,
            netEventsCount: month.netEventsCount,
            date: month.date,
            eventSchedules: month.eventSchedules,
            showEventSchedules: this.setLastStatusEvents(month)?.slice(0, 40),
            sortStatus: this.pageProperties.monthStates.find((prop) =>
              month.name.includes(prop.name)
            )?.statusFilter,
            sortStatusNum: this.pageProperties.monthStates.find((prop) =>
              month.name.includes(prop.name)
            )?.statusFilterNum,
            expanded: this.findFromLocal(month.name),
            exportCheck: this.findEpandFromLocal(month.name),
          };
        });
        this.setMonthsDynamic();
        x.months.forEach((y) => {
          this.eventSchedules = this.eventSchedules.concat(y.eventSchedules);
        });
      });
  }

  setFilter(filter?: string) {
    if (this.selectedYear) {
      if (this.pageProperties && this.pageProperties.id) {
        this.pageProperties.filterString = filter ?? '';
        this.service.setPageProperties(this.pageProperties).subscribe();
      }
      this.eventSchedules = [];
      filter ? (this.searchFilter = filter) : (this.searchFilter = '');
      this.handleSearch(this.searchType);
    }
  }
  setYear(value: number) {
    if (this.pageProperties && this.pageProperties.id) {
      this.pageProperties.year = value;
      this.service.setPageProperties(this.pageProperties).subscribe();
    }
    this.eventSchedules = [];
    this.refreshData();
  }
  goToLink(url: string) {
    if (url) {
      if (!url.startsWith('http')) {
        url = '//' + url;
      }
      window.open(url, '_blank');
    }
  }
  removeComponentTree() {
    if (this.overview) {
      this.appRef.detachView(this.overview!.hostView);
      this.overview?.destroy();
    }
    if (this.adsView) {
      this.appRef.detachView(this.adsView!.hostView);
      this.adsView?.destroy();
    }
    if (this.payStats) {
      this.appRef.detachView(this.payStats!.hostView);
      this.payStats?.destroy();
    }
    if (this.toDo) {
      this.appRef.detachView(this.toDo!.hostView);
      this.toDo?.destroy();
    }
    if (this.financials) {
      this.appRef.detachView(this.financials!.hostView);
      this.financials?.destroy();
    }
  }
  updateLoadIndexes() {
    if (this.adsView) {
      this.adsView.instance.loadEvInd = this.loadEvInd;
      this.adsView.instance.loadMonInd = this.loadMonInd;
    }
    if (this.overview) {
      this.overview.instance.loadEvInd = this.loadEvInd;
      this.overview.instance.loadMonInd = this.loadMonInd;
    }
    if (this.toDo) {
      this.toDo.instance.loadEvInd = this.loadEvInd;
      this.toDo.instance.loadMonInd = this.loadMonInd;
    }
    if (this.payStats) {
      this.payStats.instance.loadEvInd = this.loadEvInd;
      this.payStats.instance.loadMonInd = this.loadMonInd;
    }
    if (this.financials) {
      this.financials.instance.loadEvInd = this.loadEvInd;
      this.financials.instance.loadMonInd = this.loadMonInd;
    }
  }
  setMonthsDynamic() {
    if (this.adsView) {
      this.adsView.instance.months = this.months;
      this.adsView.instance.tabSelected = this.tabSelected;

      this.adsView.instance.pageProperties = this.pageProperties;
      this.adsView.instance.eventSchedules = this.eventSchedules;
      this.adsView.instance.loadEvInd = this.loadEvInd;
      this.adsView.instance.loadMonInd = this.loadMonInd;
    }
    if (this.overview) {
      this.overview.instance.months = this.months;
      this.overview.instance.tabSelected = this.tabSelected;
      this.overview.instance.pageProperties = this.pageProperties;
      this.overview.instance.eventSchedules = this.eventSchedules;
      this.overview.instance.loadEvInd = this.loadEvInd;
      this.overview.instance.loadMonInd = this.loadMonInd;
    }
    if (this.toDo) {
      this.toDo.instance.months = this.months;
      this.toDo.instance.tabSelected = this.tabSelected;
      this.toDo.instance.pageProperties = this.pageProperties;
      this.toDo.instance.eventSchedules = this.eventSchedules;
      this.toDo.instance.loadEvInd = this.loadEvInd;
      this.toDo.instance.loadMonInd = this.loadMonInd;
    }
    if (this.payStats) {
      this.payStats.instance.months = this.months;
      this.payStats.instance.tabSelected = this.tabSelected;
      this.payStats.instance.pageProperties = this.pageProperties;
      this.payStats.instance.eventSchedules = this.eventSchedules;
      this.payStats.instance.loadEvInd = this.loadEvInd;
      this.payStats.instance.loadMonInd = this.loadMonInd;
    }
    if (this.financials) {
      this.financials.instance.months = this.months;
      this.financials.instance.tabSelected = this.tabSelected;
      this.financials.instance.pageProperties = this.pageProperties;
      this.financials.instance.eventSchedules = this.eventSchedules;
      this.financials.instance.loadEvInd = this.loadEvInd;
      this.financials.instance.loadMonInd = this.loadMonInd;
    }
    // this.addlisteners();
  }

  adsView: ComponentRef<AdminAdsViewEventScheduleComponent> | null = null;
  overview: ComponentRef<AdminEventSchedulePageComponent> | null = null;
  payStats: ComponentRef<AdminPayStatsEventScheduleComponent> | null = null;
  toDo: ComponentRef<AdminToDoEventScheduleComponent> | null = null;
  financials: ComponentRef<FinancialsEsComponent> | null = null;

  countEventsForMonth(id: string) {
    var toCalc = this.months.find((x) => x.id == id);
    if (toCalc) {
      let allEvents = toCalc.eventSchedules;
      // toCalc.eventSchedules.forEach((first) => {
      //   allEvents = allEvents.concat(first.reschedules);
      //   first.reschedules.forEach((second) => {
      //     allEvents = allEvents.concat(second.reschedules);
      //   });
      // });

      toCalc!.aeCount =
        allEvents.filter((x) => x.client.aeAdvisor).length +
        allEvents.filter((x) => x.doubleEvent && x.client.aeAdvisor).length;

      toCalc!.eventsCount =
        allEvents.length + allEvents.filter((x) => x.doubleEvent).length;

      toCalc!.rescheduledCount =
        allEvents.filter((x) => x.status == 'Rescheduled').length +
        allEvents.filter((x) => x.doubleEvent && x.statusD2 == 'Rescheduled')
          .length;

      toCalc!.cancelledCount =
        allEvents.filter((x) => x.status == 'Cancelled').length +
        allEvents.filter((x) => x.doubleEvent && x.statusD2 == 'Cancelled')
          .length;

      toCalc!.netEventsCount =
        toCalc.eventsCount - toCalc.rescheduledCount - toCalc.cancelledCount;
    }
    // this.addlisteners();
  }

  updateChangableFields(
    event: IEventSchedule,
    newEvent: IEventSchedule
  ): IEventSchedule {
    if (event && newEvent && newEvent.id == event.id) {
      event.status = newEvent.status!;
      event.statusD2 = newEvent.statusD2!;
      event.pinkDot = newEvent.pinkDot!;
      event.pinkDotD2 = newEvent.pinkDotD2!;
      event.orangeDot = newEvent.orangeDot!;
      event.orangeDotD2 = newEvent.orangeDotD2!;
      event.totalInvoices = newEvent.totalInvoices!;
      event.costLead = newEvent.costLead!;
      event.grossProfit = newEvent.grossProfit!;
      event.reminderReady = newEvent.reminderReady!;
      event.payStats = newEvent.payStats!;
    }
    return event;
  }
  changeStatus(editedEventId: string) {
    this.refreshOne({ id: editedEventId });
  }

  refreshOne(newEntity: Partial<IEventSchedule>) {
    let idToFind = newEntity.id ?? '';
    if (this.parentToUpdate) {
      idToFind = this.parentToUpdate;
    }
    if (idToFind) {
      var monthId = '';
      this.months.forEach((x) => {
        if (x.eventSchedules.find((y) => y.id == idToFind)) {
          this.service
            .loadOneEventScheduleWithIncuded(idToFind)
            .subscribe((eve: IEventSchedule) => {
              let newEvent = x.eventSchedules.find((y) => y.id == idToFind);
              if (newEvent) {
                let index = x.eventSchedules.indexOf(newEvent);
                x.eventSchedules[index] = eve;
              }
              monthId = x.id;
              if (monthId) {
                this.countEventsForMonth(monthId);
                this.refreshMonth(x);
              }
              this.setMonthsDynamic();
            });
        }
      });
    }
    // this.addlisteners();
  }
  async appendComponentToBody(
    component: any,
    section: HTMLElement,
    id: string
  ) {
    // 1. Create a component reference from the component
    var proccessComponent;
    this.setMonthsDynamic();

    switch (id) {
      case 'ads-view': {
        this.adsView = this.componentFactoryResolver
          .resolveComponentFactory(
            component as Type<AdminAdsViewEventScheduleComponent>
          )
          .create(this.injector);
        proccessComponent = this.adsView;
        break;
      }
      case 'overview': {
        this.overview = this.componentFactoryResolver
          .resolveComponentFactory(
            component as Type<AdminEventSchedulePageComponent>
          )
          .create(this.injector);
        proccessComponent = this.overview;

        break;
      }
      case 'pay/stats': {
        this.payStats = this.componentFactoryResolver
          .resolveComponentFactory(
            component as Type<AdminPayStatsEventScheduleComponent>
          )
          .create(this.injector);
        proccessComponent = this.payStats;
        break;
      }
      case 'financials': {
        this.financials = this.componentFactoryResolver
          .resolveComponentFactory(component as Type<FinancialsEsComponent>)
          .create(this.injector);
        proccessComponent = this.financials;
        break;
      }
      case 'to-Do': {
        this.toDo = this.componentFactoryResolver
          .resolveComponentFactory(
            component as Type<AdminToDoEventScheduleComponent>
          )
          .create(this.injector);
        proccessComponent = this.toDo;
        break;
      }
    }
    if (proccessComponent) {
      if (id !== 'to-Do') {
        this.filterMonthsExceptToDo();
      } else {
        this.filterMonthsForToDo();
      }
      proccessComponent.instance.months = this.months;
      proccessComponent.instance.tabSelected = this.tabSelected;
      proccessComponent.instance.pageProperties = this.pageProperties;

      proccessComponent.instance.eventSchedules = this.eventSchedules;
      proccessComponent.instance.loadEvInd = this.loadEvInd;
      proccessComponent.instance.loadMonInd = this.loadEvInd;

      // this.firstMonthLoaded();
      // 2. Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(proccessComponent.hostView);
      // 3. Get DOM element from component
      const domElem = (proccessComponent.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      proccessComponent.instance.refreshData.subscribe((x) =>
        this.refreshData()
      );
      proccessComponent.instance.enterChangeStatus.subscribe((x) =>
        this.enterChangeStatus(x)
      );
      proccessComponent.instance.enterActivityLogs.subscribe((x) =>
        this.manageActivityLogs(x)
      );
      proccessComponent.instance.enterPayStats.subscribe((x) =>
        this.enterPayStats(x)
      );
      proccessComponent.instance.actionPop.subscribe((x) =>
        this.enterActionPop(x)
      );
      proccessComponent.instance.deleteConfirmation.subscribe((x) => {
        if (x.entity) {
          this.deleteConfirmation = x.entity;
          this.deleteConfirmationDate = x.date;
        }
      });
      proccessComponent.instance.refreshOne.subscribe((x) =>
        this.refreshOne(x)
      );
      proccessComponent.instance.addListeners.subscribe(
        (x) => {}
        // this.addlisteners()
      );
      proccessComponent.instance.refreshMonth.subscribe((x) =>
        this.refreshMonth(x)
      );
      proccessComponent.instance.exportCsv.subscribe((x) =>
        this.exportMonthsToCSV(x)
      );
      proccessComponent.instance.expand.subscribe((x) => this.expand(x));
      proccessComponent.instance.exportCheck.subscribe((x) =>
        this.exportCheck(x)
      );

      proccessComponent.instance.showModal.subscribe((x) => this.showModal(x));

      // 4. Append DOM element to the body
      section.appendChild(domElem);
    }
  }
  exortAllChecked() {
    let exportees = this.months.filter((x) => x.exportCheck);
    let toExport = exportees.map((month) => {
      return {
        showEventSchedules: this.setLastStatusEvents(month),
      };
    });
    if (toExport && toExport.length) {
      let events = [] as string[];

      toExport.forEach((m) =>
        m.showEventSchedules?.forEach((f) => {
          events.push(f.id);
          f.firstReschedules.forEach((f1) => {
            events.push(f1.id);
            f1.firstReschedules.forEach((f2) => {
              events.push(f2.id);
            });
            f1.secondReschedules.forEach((s2) => {
              events.push(s2.id);
            });
          });

          f.secondReschedules.forEach((s1) => {
            events.push(s1.id);
            s1.firstReschedules.forEach((f2) => {
              events.push(f2.id);
            });
            s1.secondReschedules.forEach((s2) => {
              events.push(s2.id);
            });
          });
        })
      );
      this.service
        .batchExportMonths(events, this.tabSelected, exportees[0]?.name)
        .subscribe((data: Blob) => {
          this.months.forEach((m) => {
            m.exportCheck = false;
          });
          this.pageProperties.monthStates.forEach((m) => {
            m.exportCheck = false;
          });
          this.service
            .setPageProperties(this.pageProperties)
            .subscribe((x) => this.refreshData());
        });
    } else {
      window.alert(
        'No entities are seleceted to be exported, please check some'
      );
    }
  }
  filterMonthsForToDo() {
    this.months.forEach((x) => {
      if (x.sortStatus == 'Few Spots') {
        x.showEventSchedules = ([] as IEventSchedule[]).concat(
          this.fewSpotsFilter(x)
        );
      }
    });
  }
  filterMonthsExceptToDo() {
    this.months.forEach((x) => {
      if (x.sortStatus == 'Few Spots') {
        var oldMonth = this.months.find((t) => t.id == x.id);

        if (oldMonth) {
          x.showEventSchedules = ([] as IEventSchedule[]).concat(
            this.clearfilterShowEvents(oldMonth)
          );
        }
      }
    });
  }
  mapShownEvents() {
    this.months = this.months.map((month) => {
      return {
        ...month,
        showEventSchedules: month.showEventSchedules?.slice(0, 40),
        showEventsCount: 40,
      };
    });
  }
  async changeTab(tab: string) {
    this.tabSelected = tab;
    if (this.pageProperties && this.pageProperties.id) {
      this.pageProperties.tabSelected = tab;

      this.service.setPageProperties(this.pageProperties).subscribe();
    }
    this.mapShownEvents();
    this.monthsLoading = true;
    var monthSection = document.getElementById('monthSection') as HTMLElement;
    this.removeComponentTree();
    if (monthSection) {
      switch (tab) {
        case 'ads-view': {
          this.appendComponentToBody(
            AdminAdsViewEventScheduleComponent,
            monthSection,
            tab
          );
          break;
        }
        case 'overview': {
          this.appendComponentToBody(
            AdminEventSchedulePageComponent,
            monthSection,
            tab
          );
          break;
        }
        case 'pay/stats': {
          this.appendComponentToBody(
            AdminPayStatsEventScheduleComponent,
            monthSection,
            tab
          );
          break;
        }
        case 'financials': {
          this.appendComponentToBody(FinancialsEsComponent, monthSection, tab);
          break;
        }
        case 'to-Do': {
          this.appendComponentToBody(
            AdminToDoEventScheduleComponent,
            monthSection,
            tab
          );
          break;
        }
      }
    }
  }

  fewSpotsFilter(month: IMonthWithEvents) {
    let newCollection = [] as IEventSchedule[];
    var oldMonth = this.months.find((x) => x.id == month.id);

    if (oldMonth) {
      if (this.pageProperties && this.pageProperties.id) {
        if (this.pageProperties && this.pageProperties.id) {
          var monthFromProps = this.pageProperties.monthStates.find((prop) =>
            oldMonth?.name.includes(prop.name)
          );
          if (monthFromProps) {
            this.pageProperties.monthStates.find((prop) =>
              oldMonth?.name.includes(prop.name)
            )!.statusFilter = month.sortStatus;
            this.pageProperties.monthStates.find((prop) =>
              oldMonth?.name.includes(prop.name)
            )!.statusFilterNum = month.sortStatusNum ?? 0;
            this.service.setPageProperties(this.pageProperties).subscribe();
          }
        }
      }
      switch (month.sortStatusNum) {
        case 1: {
          newCollection = oldMonth.eventSchedules.sort(
            (a, b) =>
              // new Date(b.fewSpots) - new Date(a.fewSpots)
              new Date(a.fewSpots).getTime() - new Date(b.fewSpots).getTime()
          );
          break;
        }

        case 2: {
          newCollection = oldMonth.eventSchedules.sort(
            (a, b) =>
              new Date(b.fewSpots).getTime() - new Date(a.fewSpots).getTime()
          );
          break;
        }
        default: {
          newCollection = oldMonth.eventSchedules.sort(
            (a, b) =>
              new Date(a.fewSpots).getTime() - new Date(b.fewSpots).getTime()
          );
          break;
        }
      }
    }
    return newCollection;
  }
  filterShowEvents(month: IMonthWithEvents, oldMonth: IMonthWithEvents) {
    let newCollection = [] as IEventSchedule[];
    if (this.pageProperties && this.pageProperties.id) {
      var monthFromProps = this.pageProperties.monthStates.find((prop) =>
        oldMonth?.name.includes(prop.name)
      );
      if (monthFromProps) {
        this.pageProperties.monthStates.find((prop) =>
          oldMonth?.name.includes(prop.name)
        )!.statusFilter = month.sortStatus;
        this.pageProperties.monthStates.find((prop) =>
          oldMonth?.name.includes(prop.name)
        )!.statusFilterNum = 0;
        this.service.setPageProperties(this.pageProperties).subscribe();
      }
    }
    newCollection = oldMonth.eventSchedules.filter(
      (x) =>
        x.status == month?.sortStatus ||
        (x.doubleEvent && x.statusD2 == month?.sortStatus)
    );
    return newCollection;
  }
  clearfilterShowEvents(oldMonth: IMonthWithEvents) {
    let newCollection = [] as IEventSchedule[];
    newCollection = oldMonth.eventSchedules.sort((a, b) => {
      return (
        new Date(a.formatedDate).getTime() - new Date(b.formatedDate).getTime()
      );
    });
    return newCollection;
  }

  refreshMonth(month: IMonthWithEvents) {
    var oldMonth = this.months.find((x) => x.id == month.id);
    let index = this.months.indexOf(month);

    if (oldMonth) {
      this.months.find((x) => x.id == month.id)!.exportCheck =
        month.exportCheck;
    }
    if (oldMonth && oldMonth.showEventSchedules && index != -1) {
      let newCollection;
      if (month.sortStatus == 'Few Spots') {
        newCollection = this.fewSpotsFilter(month);
      } else if (month.sortStatus) {
        newCollection = this.filterShowEvents(month, oldMonth);
      } else {
        if (this.pageProperties && this.pageProperties.id) {
          var monthFromProps = this.pageProperties.monthStates.find((prop) =>
            oldMonth?.name.includes(prop.name)
          );
          if (monthFromProps) {
            this.pageProperties.monthStates.find((prop) =>
              oldMonth?.name.includes(prop.name)
            )!.statusFilter = undefined;
            this.pageProperties.monthStates.find((prop) =>
              oldMonth?.name.includes(prop.name)
            )!.statusFilterNum = 0;

            this.service.setPageProperties(this.pageProperties).subscribe();
          }
        }
        newCollection = this.clearfilterShowEvents(oldMonth);
      }

      if (
        month?.sortStatusNum &&
        month?.sortStatus != 'Few Spots' &&
        typeof month?.sortStatusNum == typeof 123 &&
        month?.sortStatusNum != 0
      ) {
        newCollection = newCollection.filter(
          (x) =>
            +x.statusNum == month?.sortStatusNum ||
            (x.doubleEvent && +x.statusNumD2 == month?.sortStatusNum)
        );
        if (this.pageProperties && this.pageProperties.id) {
          var monthFromProps = this.pageProperties.monthStates.find((prop) =>
            oldMonth?.name.includes(prop.name)
          );
          if (monthFromProps) {
            this.pageProperties.monthStates.find((prop) =>
              oldMonth?.name.includes(prop.name)
            )!.statusFilterNum = month.sortStatusNum;
            this.service.setPageProperties(this.pageProperties).subscribe();
          }
        }
      }
      oldMonth.showEventSchedules = ([] as IEventSchedule[]).concat(
        newCollection
      );

      this.countEventsForMonth(month.id);
      this.setMonthsDynamic();
    }
    // this.addlisteners();
  }
  // changeStatus(id: string, value: DatalistOption, date: number) {
  //   date == 1
  //     ? this.refreshOne({ id: id, status: value.value })
  //     : this.refreshOne({ id: id, statusD2: value.value });
  // }

  setSecondDateAsPrimary(post: IEventSchedule) {
    this.service.setSecondDateAsPrimary(post.id).subscribe({
      complete: () => {
        this.refreshData();
      },
    });
  }

  handleDeleteClick(post: IEventSchedule) {
    this.service.delete(post.id).subscribe({
      complete: () => {
        this.refreshData();
      },
    });
  }

  checkReminder(id: string) {
    if (id) {
      this.service.checkReminder(id).subscribe((x) => this.refreshOne(x));
    }
  }
  esIdOnPopUp: string = '';
  parentToUpdate: string = '';

  enterActionPop(emitter: IActionPopEmitter) {
    this.actionLeft = emitter.left - 190 ?? 0;
    this.actionTop = emitter.top - 10 ?? 0;
    this.actionEvent = emitter.event;
    this.actionDate = emitter.date;
  }
  enterPayStats(emitter: IChangePayStatsEmitter) {
    this.esIdOnPopUp = emitter.id;
    this.payStatsLeft = emitter.payStatsLeft;
    this.payStatsTop = emitter.payStatsTop;
    this.parentToUpdate = emitter.parentId ?? '';
  }
  secondHandleDeleteClick(post: IEventSchedule) {
    this.service.deleteSecond(post.id).subscribe({
      complete: () => {
        this.refreshData();
      },
    });
  }
  manageDeleteConfirmation() {
    if (this.deleteConfirmation && this.deleteConfirmationDate) {
      switch (this.deleteConfirmationDate) {
        case 1: {
          if (this.deleteConfirmation.doubleEvent)
            this.setSecondDateAsPrimary(this.deleteConfirmation);
          else this.handleDeleteClick(this.deleteConfirmation);
          break;
        }
        case 2: {
          this.secondHandleDeleteClick(this.deleteConfirmation);
          break;
        }
        default: {
          console.log('can not find entity to delete');
          break;
        }
      }
      this.deleteConfirmation = null;
      this.deleteConfirmationDate = null;
    }
  }
  ngOnDestroy() {
    if (this.activesubscription) {
      this.activesubscription.unsubscribe();
    }
    // this.removeComponentTree();
  }
}
