<div class="actionHover text-break text-center d-block colorBlack max-height600px pos-absolute" [style.top.px]="top"
  [style.left.px]="left">
  <div class="dropdown-menu-item option w-100" *ngIf="tabSelected == 'overview'" (click)="openActivityLogs.emit()"
    data-bs-toggle="modal" data-bs-target="#activityLogModal">
    Activity Logs
  </div>
  <div class="dropdown-menu-item option w-100" [routerLink]="['/admin/eventSchedule/eventSchedule/', localEvent?.id]">
    Edit
  </div>
  <div *ngIf="actionDate == 1; else singleDateEventBlock">
    <div class="dropdown-menu-item option w-100" data-bs-target="#esDeleteConfirmModal" title="Delete" (click)="
        this.deleteConfirmation.emit({ entity: this.localEvent, date: actionDate })
      ">
      Delete <span *ngIf="localEvent?.doubleEvent"> First</span>
    </div>
  </div>
  <ng-template #singleDateEventBlock>
    <div class="dropdown-menu-item option w-100" *ngIf="localEvent?.doubleEvent"
      (click)="deleteConfirmation.emit({ entity: localEvent, date: actionDate })"
      data-bs-target="#esDeleteConfirmModal">
      Delete Second
    </div>
  </ng-template>

  <div class="dropdown-menu-item option w-100" (click)="openTicket() " *ngIf="showOpenTicket">Open
    Ticket</div>
</div>