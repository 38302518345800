<!--<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit event topic label">Edit event Topic</h2>-->

<div class="row">
  <h1 class="mb-4 col" *ngIf="id === 'new'" i18n="Create event topic label">
    Create event Topic
  </h1>
  <div *ngIf="id === 'new'" class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex">
      <a
        routerLink="/admin/topics"
        class="d-flex justify-content-center btn dark-wfrBlue text-white"
        style="
          justify-content: end;
          width: 140px !important;
          height: 40px !important;
        "
      >
        <img src="assets/img/backArrow.svg" />
        <span class="">Back</span>
      </a>
    </div>
  </div>
</div>

<div class="row pe-4">
  <h1 class="mb-4 col" *ngIf="id !== 'new'" i18n="Edit event topic label">
    Update event Topic
  </h1>
  <div *ngIf="id !== 'new'" class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex">
      <a
        routerLink="/admin/topics"
        class="d-flex justify-content-around btn dark-wfrBlue text-white"
        style="
          justify-content: end;
          width: 140px !important;
          height: 40px !important;
        "
      >
        <img src="assets/img/backArrow.svg" />
        <span class="">Back</span>
      </a>
    </div>
  </div>
</div>

<div *ngIf="id === 'new' || topic" class="row mb-4">
  <div class="col-12">
    <app-topics-editform
      [editedTopic]="topic"
      (TopicSubmit)="createTopic($event)"
    ></app-topics-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the topic"
      i18n-title="Topic submit error alert"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
