import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { ITopic } from '../../../../state/event-schedule.repository';
import { TopicsRepository } from '../../../../state/topics.repository';
import { TopicsService } from '../../../../state/topics.service';

@Component({
  selector: 'app-topics-editform',
  templateUrl: './topics-editform.component.html',
  styleUrls: ['./topics-editform.component.scss'],
})
export class TopicsEditformComponent {
  editForm: UntypedFormBuilder | any;
  @Input() editedTopic?: ITopic | null;
  submitErrors: string[] | null = null;
  @Output() TopicSubmit = new EventEmitter<Partial<ITopic>>();
  ClientsOptions: DatalistOption[] | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: TopicsService,
    private repository: TopicsRepository,
    private clientService: CompaniesService
  ) {}
  @Input() set clients(value: ICompanyMarker[] | null) {
    if (!value) {
      this.ClientsOptions = null;
    } else {
      this.ClientsOptions = value.map((x) => ({
        value: x.id,
        label: x.clientCode ? x.clientCode + ' - ' + x.title : x.title,
      }));
    }
  }
  ngOnInit(): void {
    this.clientService.loadAll().subscribe((x) => {
      this.clients = x;
    });
    this.editForm = this.formBuilder.group({
      name: [this.editedTopic?.name, Validators.required],
      companyId: this.editedTopic?.companyId,
      googleSheetUrl: this.editedTopic?.googleSheetUrl,
    });
  }
  onSubmit() {
    const id = this.editedTopic?.id?.toString();
    const tenantId = this.editedTopic?.tenantId?.toString();
    const name = this.editForm.value?.name?.toString();
    const googleSheetUrl = this.editForm.value?.googleSheetUrl?.toString();
    const companyId = this.editForm.value?.companyId?.toString();

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    this.TopicSubmit.emit({
      id,
      tenantId,
      name,
      googleSheetUrl,
      companyId,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
