import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  IEventSchedule,
  PayStatsOptions,
  StatusesOptions,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-change-pay-stats-pop-up',
  templateUrl: './change-pay-stats-pop-up.component.html',
  styleUrls: ['./change-pay-stats-pop-up.component.scss'],
})
export class ChangePayStatsPopUpComponent {
  StatusesOptions: DatalistOption[] = PayStatsOptions;
  @Input() esIdOnPopUp: string | null = null;
  @Input() statusIndex: number | null = null;
  @Input() isOrange: boolean = false;

  @Input() top: number = 0;
  @Input() left: number = 0;
  @Output() submit = new EventEmitter<DatalistOption>();
  constructor(
    public repo: EventScheduleRepository,
    public service: EventScheduleService
  ) {}
  updateValue($event?: DatalistOption) {
    if (this.esIdOnPopUp && $event) {
      this.service
        .changePayStats(this.esIdOnPopUp, $event.value)
        .subscribe((x) => this.submit.emit($event));
    } else if (!$event && this.esIdOnPopUp) {
      this.service
        .changePayStats(this.esIdOnPopUp, 'clear')
        .subscribe((x) => this.submit.emit({ label: 'clear', value: 'clear' }));
    }
  }
}
