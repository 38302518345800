<div style="width: auto">
  <div class="d-flex px-2 h60 last-event">
    <div
      class="d-flex align-items-center"
      style="width: 200px"
      type="button"
      (click)="
        enterChangeStatus.emit({
          entityId: event.id,
          parentEntityId: mainParentId,
          date: 1,
          pageX: $event.pageX,
          pageY: $event.pageY
        })
      "
    >
      <app-event-status [status]="event.status" [statusNum]="+event.statusNum">
      </app-event-status>
    </div>
    <div class="d-flex align-items-center" style="width: 200px">
      <div
        class="text-break oneLineText pointer ccBinding"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px" (click)="goToLink(event.clientUrl)">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.firstReschedules?.length"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.color_orange]="event.twoPartSetup"
          >
            {{ event.client.title ?? "" }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.formatedDateAdmin ?? "" }}

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.asap ? "Yes" : "No" }}

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 400px">
      <div class="text-break oneLineText">
        {{ event.funnel?.name ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 400px">
      <div class="text-break oneLineText">
        {{ event.time ?? event.prebuildTime?.name ?? "" }}
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center reminderBinding"
      style="width: 100px"
      (click)="
        enterBell.emit({
          entity: event,
          pageX: $event.pageX,
          pageY: $event.pageY,
          parentEntityId: mainParentId
        })
      "
    >
      <div
        class="pointer"
        [class.graySclale]="!event.reminderReady"
        *ngIf="event.reminder"
      >
        <img src="assets/img/bell-active-green20x20.svg" />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 500px">
      <div
        [class.readyClass]="event.clientTouchReady"
        class="text-break oneLineText"
      >
        {{ event.clientTouch ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div
        [class.readyClass]="event.emailBlastScheduled"
        class="text-break oneLineText"
      >
        {{ event.emailBlast1st ?? "" }}

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div
        [class.readyClass]="event.emailBlastScheduled"
        class="text-break oneLineText"
      >
        {{ event.emailBlast2nd ?? "" }}

        <br />
      </div>
    </div>
    <div
      [class.readyClass]="event.fewSpotsScheduled"
      class="d-flex align-items-center"
      style="width: 500px"
    >
      <div class="text-break oneLineText">
        {{ event.fewSpots ?? "" }}
        <br />
      </div>
    </div>
    <div
      [class.readyClass]="event.wfrCall1Done"
      class="d-flex align-items-center"
      style="width: 500px"
    >
      <div class="text-break oneLineText">
        {{ event.wfrCall1Date ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        {{ event.wfrCall1Funnel?.name ?? "" }}
        <br />
      </div>
    </div>

    <div
      [class.readyClass]="event.wfrCall2Done"
      class="d-flex align-items-center"
      style="width: 500px"
    >
      <div class="text-break oneLineText">
        {{ event.wfrCall2Date ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        {{ event.wfrCall2Funnel?.name ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        {{ event.wfrReminder ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.adSpent ?? event.otherAds + event.metaAds }}
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 50px; right: 0"
    >
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 1,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>

        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
            title="Edit"
            i18n-title="Edit button"
          >
            <img class="editBinding" src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            (click)="deleteConfirmation.emit({ entity: event, date: 1 })"
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            title="Delete"
            i18n-title="Delete button"
          >
            <img
              type="button"
              class="deleteBinding"
              src="assets/img/delete18x20.svg"
            />
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <span *ngIf="event.firstReschedules?.length">
    <div *ngFor="let firstEv of event.firstReschedules">
      <app-es-todo-double-row
        (enterChangeStatus)="enterChangeStatus.emit($event)"
        (enterBell)="enterBell.emit($event)"
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        [mainParentId]="mainParentId"
        [paddStart]="paddStart + 20"
        [event]="firstEv"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-todo-double-row>
    </div>
  </span>
  <div class="d-flex px-2 h60 last-event" *ngIf="event.doubleEvent">
    <div
      class="d-flex align-items-center"
      style="width: 200px"
      type="button"
      (click)="
        enterChangeStatus.emit({
          entityId: event.id,
          parentEntityId: mainParentId,
          date: 2,
          pageX: $event.pageX,
          pageY: $event.pageY
        })
      "
    >
      <app-event-status
        [status]="event.statusD2"
        [statusNum]="+event.statusNumD2"
      >
      </app-event-status>
    </div>
    <div class="d-flex align-items-center" style="width: 200px">
      <div
        class="text-break oneLineText pointer ccBinding"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px" (click)="goToLink(event.clientUrl)">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.secondReschedules?.length"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.color_orange]="event.twoPartSetup"
          >
            {{ event.client.title ?? "" }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.formatedDate2Admin ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.asap ? "Yes" : "No" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 400px">
      <div class="text-break oneLineText">
        {{ event.funnelSecond?.name ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 400px">
      <div class="text-break oneLineText">
        {{ event.time2 ?? event.prebuildTime2?.name ?? "" }}
        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 100px"></div>
    <div class="d-flex align-items-center" style="width: 500px"></div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div
        [class.readyClass]="event.emailBlastD2Scheduled"
        class="text-break oneLineText"
      >
        {{ event.emailBlast1stD2 ?? "" }}

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div
        [class.readyClass]="event.emailBlastD2Scheduled"
        class="text-break oneLineText"
      >
        {{ event.emailBlast2ndD2 ?? "" }}

        <br />
      </div>
    </div>
    <div
      [class.readyClass]="event.fewSpotsD2Scheduled"
      class="d-flex align-items-center"
      style="width: 500px"
    >
      <div class="text-break oneLineText">
        {{ event.fewSpotsD2 ?? "" }}
        <br />
      </div>
    </div>
    <div
      [class.readyClass]="event.wfrCall1D2Done"
      class="d-flex align-items-center"
      style="width: 500px"
    >
      <div class="text-break oneLineText">
        {{ event.wfrCall1D2Date ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        {{ event.wfrCall1D2Funnel?.name ?? "" }}
        <br />
      </div>
    </div>
    <div
      [class.readyClass]="event.wfrCall2D2Done"
      class="d-flex align-items-center"
      style="width: 500px"
    >
      <div class="text-break oneLineText">
        {{ event.wfrCall2D2Date ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        {{ event.wfrCall2D2Funnel?.name ?? "" }}
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div
        [class.readyClass]="event.wfrReminderReady"
        class="text-break oneLineText"
      ></div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px"></div>
    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 50px; right: 0"
    >
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 2,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>
        <!-- <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div> -->
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
            title="Edit"
            i18n-title="Edit button"
          >
            <img class="editBinding" src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            (click)="deleteConfirmation.emit({ entity: event, date: 2 })"
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            title="Delete"
            i18n-title="Delete button"
          >
            <img
              type="button"
              class="deleteBinding"
              src="assets/img/delete18x20.svg"
            />
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <span *ngIf="event.secondReschedules?.length">
    <div *ngFor="let secondEv of event.secondReschedules">
      <app-es-todo-double-row
        (enterChangeStatus)="enterChangeStatus.emit($event)"
        (enterBell)="enterBell.emit($event)"
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        [mainParentId]="mainParentId"
        [paddStart]="paddStart + 20"
        [event]="secondEv"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-todo-double-row>
    </div>
  </span>
</div>
