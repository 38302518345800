import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import {
  EventScheduleRepository,
  IEventSchedule,
  StatusesOptions,
} from 'src/app/state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';

@Component({
  selector: 'app-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: ['./change-status-modal.component.scss'],
})
export class ChangeStatusModalComponent {
  StatusesOptions: DatalistOption[] = StatusesOptions;
  OptionsWithFewSpots: DatalistOption[] = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Ready', value: 'Ready' },
    { label: 'ON', value: 'ON' },
    { label: 'OFF', value: 'OFF' },
    {
      label: 'Automatic',
      value: 'Automatic',
      subMenu: [
        { label: '1/4', value: '1' },
        { label: '2/4', value: '2' },
        { label: '3/4', value: '3' },
        { label: '4/4', value: '4' },
      ],
    },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Rescheduled', value: 'Rescheduled' },
    {
      label: 'Few Spots',
      value: 'Few Spots',
      subMenu: [
        { label: 'Ascending', value: '1' },
        { label: 'Descending', value: '2' },
      ],
    },
  ];

  @Input() eventScheduleOnPopUp: IEventSchedule | null = null;
  @Input() statusIndex: number | null = null;
  @Output() mouseLeave = new EventEmitter<DatalistOption>();

  @Input() top: number = 0;
  @Input() left: number = 0;
  @Input() isMonthStatus: boolean = false;
  @Input() hasFewSpotsSort: boolean = false;
  @Input() eventId: string = '';

  @Output() submit = new EventEmitter<DatalistOption>();
  constructor(
    public repo: EventScheduleRepository,
    public service: EventScheduleService
  ) {}

  updateValue($event?: DatalistOption, sublabelNum?: number) {
    if (this.eventId && $event) {
      this.service
        .changeStatus(this.eventId, $event.value, this.statusIndex ?? 1)
        .subscribe((x) => this.submit.emit($event));
    } else if (this.isMonthStatus && $event) {
      this.submit.emit({ label: $event.label, value: sublabelNum });
    } else if (!$event) {
      this.submit.emit();
    }
  }
}
