import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { IFunnel, ITopic } from './event-schedule.repository';
import { FunnelsRepository } from './funnels.repository';
import { TopicsRepository } from './topics.repository';

const API = '/api/topics';

@Injectable({ providedIn: 'root' })
export class TopicsService extends BaseService<ITopic> {
  constructor(http: HttpClient, repo: TopicsRepository) {
    super(API, http, repo);
  }
  sortCustom(sortBy: SortState, search?: string, take: number = 25) {
    this.repo.setSort(sortBy);
    return this.reloadPage(search, take);
  }
  loadOneTopic(id: string) {
    return this.http.get<ITopic>(API + '/loadOneTopic/' + id);
  }
  loadAllTopics() {
    return this.http.get<ITopic[]>(API + '/loadAll');
  }
  postTopic(contact: Partial<ITopic>) {
    return this.http.post<ITopic>(API + '/postTopic', contact);
  }
  updateTopic(contact: Partial<ITopic>) {
    return this.http.put<ITopic>(API + '/updateTopic', contact);
  }
  loadGeneral() {
    return this.http.get<ITopic[]>(API + '/loadGeneral');
  }
  loadByClientId(id: string) {
    return this.http.get<ITopic[]>(API + '/loadByClientId/' + id);
  }
}
